import { RefObject, useEffect, useState } from 'react';

import debounce from 'lodash.debounce';
import sanitize from 'sanitize-html';

interface LineClampParameters {
  accommodatedCharactersNumber: number;
}

const DEBOUNCE_TIME_IN_MS = 300;

export const useLineClamp = <T extends HTMLElement>(
  referenceObject: RefObject<T | null>,
  correctionFactor = 0,
): LineClampParameters => {
  const [accommodatedCharactersNumber, setAccommodatedCharactersNumber] = useState(Infinity);

  useEffect(() => {
    const container = referenceObject.current;
    const isContainerAvailable = !!container;

    if (!isContainerAvailable) {
      return;
    }

    const calculateVisibleContainerTextLength = debounce((): void => {
      setAccommodatedCharactersNumber(Infinity);

      const containerText = container.innerText || '';
      const containerWorkingText = `<span>${containerText.replace(/ /g, '</span> <span>')}</span>`;
      container.innerHTML = sanitize(containerWorkingText); // nosemgrep
      const wordsInContainerInReversedOrder = Array.from(
        container.querySelectorAll('span'),
      ).reverse();
      const containerBottomBoundary = container.offsetTop + container.clientHeight;
      const indexOfLastVisibleWordInContainer = wordsInContainerInReversedOrder.findIndex(
        word => word.offsetTop <= containerBottomBoundary,
      );
      const wordsInContainer = Array.from(container.querySelectorAll('span'));
      const wordsNumber = wordsInContainer.length;
      const textVisibleInContainer = wordsInContainer
        .slice(0, wordsNumber - indexOfLastVisibleWordInContainer)
        .map(word => word.innerText)
        .join(' ');
      const visibleTextLength = textVisibleInContainer.length;
      const isLineClampOn = container.clientHeight !== container.scrollHeight;
      const resultingTextLength = isLineClampOn
        ? visibleTextLength + correctionFactor
        : containerText.length;

      setAccommodatedCharactersNumber(resultingTextLength);
    }, DEBOUNCE_TIME_IN_MS);

    window.addEventListener('resize', calculateVisibleContainerTextLength);

    calculateVisibleContainerTextLength();

    return () => {
      window.removeEventListener('resize', calculateVisibleContainerTextLength);
    };
  }, [correctionFactor, referenceObject]);

  return { accommodatedCharactersNumber };
};
